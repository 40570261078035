import classNames from 'classnames';
import Head from 'next/head';
import React from 'react';

// import RightDrawerItems from '../RightDrawerItems';
import dispatchers from './dispatchers';
import connect from '../../../lib/connect';
import Loader from '../../partial/Loader';
import Header from '../Header';
import LeftDrawerItems from '../LeftDrawerItems';
import Snackbar from '../Snackbar';

interface Props extends GlobalProps {
  toggleLeftDrawer: any;
  children: any;
  noPadding?: boolean;
}

const leftDrawerWidth = 225;
const isDev = process.env.ENV === 'development';
const styles = (theme: any): any => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  content: {
    overflow: 'scroll',
    height: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: theme.spacing(1),
    // overflowX: 'scroll',
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(1) },
  },
  noPaddingContent: {
    overflow: 'scroll',
    height: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: { padding: theme.spacing(1) },
  },
  contentLeft: {
    paddingTop: 56,
    width: `calc(100vw - ${parseInt(theme.spacing(3))}px)`,
    marginLeft: -leftDrawerWidth,
  },
  contentShift: {
    width: `calc(100vw - ${parseInt(theme.spacing(3)) + leftDrawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShiftLeft: { marginLeft: 0 },
});

const layout = (props: Props) => {
  const {
    classes,
    children,
    redux: { state },
  } = props;
  const isAuthenticated = !!state?.user?.id;

  // @ts-ignore
  if (isAuthenticated && process.browser) {
    // @ts-ignore
    window.Tawk_API = window.Tawk_API || {};
    // @ts-ignore
    window.Tawk_API.visitor = {
      name: state?.user?.name || state?.user?.email?.split('@')?.[0],
      email: state?.user?.email,
    };

    // @ts-ignore
    window.Tawk_API.onLoad = () => {
      const data = {
        tenant: state?.user?.tenant?.key,
        dashboard: location.hostname,
      };

      // @ts-ignore
      window.Tawk_API.setAttributes(data, err => {
        // ignore
      });
    };
  }

  return (
    <div className={classes.root}>
      <Head>
        {!isDev && isAuthenticated && <script src="//embed.tawk.to/62f8bf8b37898912e962e6e8/default" async />}
      </Head>
      <Header />
      <LeftDrawerItems />
      {/* <RightDrawerItems/> */}
      <main
        className={classNames(props.noPadding ? classes.noPaddingContent : classes.content, classes.contentLeft, {
          [classes.contentShift]: state.leftDrawer,
          [classes.contentShiftLeft]: state.leftDrawer,
        })}
      >
        {children}
      </main>
      <Snackbar />
      {state.loading && <Loader />}
    </div>
  );
};

export default connect({ styles, dispatchers })(layout);
